<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>账号中心</el-breadcrumb-item>
              <el-breadcrumb-item>退换货</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_rights">
                <div class="box_right_top">退换货</div>
                <div class="box_right_cent">
                    <div class="sales-box">
                        <div class="sales-top">
                           <div class="sales-text">若您购买的商品出现问题，可以在这里申请相关售后服务。</div>
                           <div class="sales-changing flex-between">
                               <div class="changing flex-start">
                                   <span>申请返修退换货</span>
                                    <div class="sales-changing-btn" @click="queryorder()">查询售后记录</div> 
                               </div>
                               <div class="sales-changing-btn" @click="dialogVisible = true">联系客服</div>
                           </div>
                           <div class="flex-start sales-query">
                               <div class="flex-start changing-box">
                                   <el-input v-model="orderNum" class="changing-input" style="width: 200px" placeholder="请输入订单号进行查询" clearable></el-input>
                                   <div class="changing-btn" @click="changingBtn()">查询</div>
                               </div>
                           </div>
                        </div>
                        <div class="sales-cent">
                            <el-table
                            :data="tableData"
                            v-loading="tableLoading"
                            border :header-cell-style="{background:'#cccccc',color:'#333',textAlign: 'center'}"
                            :cell-style="{ textAlign: 'center' }"
                            style="width: 100%;text-align: center;">
                                <el-table-column
                                prop="orderNo"
                                label="订单号"
                                width="200">
                                </el-table-column>
                                <el-table-column
                                prop="infoList"
                                label="订单商品">
                                <template slot-scope="{row}">
                                  <div v-if="Array.isArray(row.infoList)" class="rowBoxes flex-start">
                                    <div  v-for="(adr, index) in row.infoList" :key="index" class="rowBox flex-start">
                                      <div>
                                        <img :src="adr.goodsPic" alt="">
                                        <p class="applyBtn" @click="applyBtn(adr.orderGoodsId)">申请</p>
                                      </div>
                                    </div>
                                  </div>
                                  <span v-else>{{row.name}}</span>
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="createTime"
                                label="下单时间"
                                width="220">
                                </el-table-column>
                            </el-table>
                            <div class="block" v-show="tableData.length!=0">
                                <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page.sync="currentPage"
                                :page-size="100"
                                layout="prev, pager, next, jumper"
                                :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                
                </div>
                
              </div>
            </div>
            <el-dialog
              :visible.sync="dialogVisible"
              width="20%">
              <div>
                <div style="margin-bottom:5px;">电话1</div>
                <div>05922505012</div>
              </div>
              <div style="margin:15px 0;">
                <div style="margin-bottom:5px;">电话2</div>
                <div>18650006780</div>   
              </div>
              <div>
                <div>工作日  上午8：30-12：00，下午13：30-18：00</div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关 闭</el-button>
              </span>
            </el-dialog>
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { returnOrder } from "@/api/order/aftersale"
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
        currentPage:1,
        ruleForm: {
          name: ''
        },
        rules: {
          name: [
            { required: true, message: '请输入收货人', trigger: 'blur' },
          ],
        },
        tableData: [],
        tableLoading: false,
        pageNum:1,
        pageSize:15,
        total:0,
        orderNum:'',
        dialogVisible:false
      }
  },
  created() {
    this.orderNum = this.$route.query.orderNo ?? ''
    this.returnOrder(this.$route.query.orderNo ?? '',this.pageNum,this.pageSize)
  },
  methods:{
      addTicket(){
        this.$router.replace({ name: 'AddTicket' })
      },
      handleSizeChange(val) {
        this.returnOrder('',val,this.pageSize)
      },
      handleCurrentChange(val) {
        this.returnOrder('',val,this.pageSize)
      },
      queryorder(){
        this.$router.replace({ name: 'SalesRecord' })
      },
      //获取退款退货
      returnOrder(orderNo,pageNum,pageSize){
        this.tableLoading = true
        let data={
          "orderNo": orderNo,
          "pageNum": pageNum,
          "pageSize": pageSize,
        }
        returnOrder(data).then((data)=>{
          this.tableLoading = false
          if(data.code=='00000'){
            if(data.data.records.length==0){
              this.tableData=[]
            }else{
              let arr = []
              data.data.records.forEach((item)=>{
                if(item.totalAbleAfterSaleNum==0){
                  return
                }
                let child = []
                item.infoList.forEach((itm)=>{
                  if(itm.ableRefundNum===0){
                    return
                  }
                  if(item.orderAssociationType==2){
                    itm.goodsPic="http://img13.360buyimg.com/n1/"+itm.goodsPic
                  }
                  child.push(itm)
                })
                item.infoList = child
                // if(item.orderAssociationType==4&&item.state!=5){
                //   data.data.records.splice(index,1)
                // }
                arr.push(item)
              })
              this.tableData=arr
              // this.tableData.forEach(item=>{
              //   if(item.orderAssociationType==2){
              //     item.infoList.forEach(itm=>{
              //       itm.goodsPic="http://img13.360buyimg.com/n1/"+itm.goodsPic
              //     })
              //   }
              // })
              this.total=data.data.total
            }
          }
          
        }).catch( () => {
          this.tableLoading = false
        })
      },
      //查询
      changingBtn(){
        this.returnOrder(this.orderNum,this.pageNum,this.pageSize)
      },
      //申请
      applyBtn(id){
        let list=[]
        this.tableData.forEach(item=>{
          item.infoList.forEach(itm=>{
            if(itm.orderGoodsId==id){
              let obj={}
              obj.orderNo=item.orderNo
              obj.phoneNo= item.phoneNo
              obj.state= item.state
              obj.address=item.address
              obj.createTime=item.createTime
              obj.customerName=item.customerName
              obj.number=itm.ableRefundNum
              obj.goodsId=itm.goodsId
              obj.goodsPic= itm.goodsPic
              obj.goodsPrice= itm.goodsPrice
              obj.orderGoodsId=itm.orderGoodsId
              obj.specValues=itm.specValues
              obj.goodsName=itm.goodsName
              obj.orderAssociationType=item.orderAssociationType
              obj.payTime=item.payTime
              list.push(obj)
            }
          })
        })
        this.$router.push({ name: 'ApplySales',query:{index:JSON.stringify(list)} })
      }
      
  }

}
</script>

<style lang="scss">
.settings_box{
  width: 100%;
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_rights{
    width: 87%;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .sales-box{
        .sales-top{
            padding: 20px;
            font-size: 15px;
            .sales-text{
                margin-bottom: 20px;
            }
            .sales-changing{
                margin-bottom: 20px;
                .changing>span{
                    color: #CD9F49;
                    border-bottom: 1px solid #CD9F49;
                    margin-right: 15px;
                }
                .sales-changing-btn {
                  cursor: pointer;
                  &:hover {
                    color: #CD9F49;
                  }
                }
            }
            .sales-query{
                .changing-box{
                    margin-right: 10px;
                }
                .changing-input{
                   width: 180px;
                }
                .changing-btn{
                    width: 55px;
                    height: 28px;
                    line-height: 28px;
                    text-align: center;
                    font-size: 14px;
                    color: #fff;
                    background: #CD9F49;
                    border-radius: 4px;
                    margin-left: 15px;
                    cursor: pointer;
                }
            }
        }
        
    }
    .sales-cent{
        margin-bottom: 30px;
        .block{
            text-align: center;
            margin: 15px 0;
        }
        .el-table__header{
          width: 100%;
        }
        .rowBoxes {
          overflow-x: auto;
          padding-bottom: 10px;
        }
        .rowBox{
          margin-right: 15px;
          img{
            width: 50px;
            height: 50px;
            
          }
          .applyBtn{
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              border-radius: 5px;
              background: #d7d7d7;
              margin-top: 8px;
              cursor: pointer;
            }
        }
        
    }
   
  }
}
</style>